import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Grid, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Autocomplete } from '@material-ui/lab';
import axios from '~util/axios';
import { appendOrReplaceValueInUrlParams } from '~util/query';
import useQuery from '~hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { CustomerDetails, UserDetails } from '~store/actions/ActionTypes';
import { setSimulatedCustomer, setSimulatedUser } from '~store/actions/agents';
import { resetSend, resetRate } from '~store/actions';
import { RootStore } from '~store/store';

import DropdownPaper from '~components/Forms/DropdownPaper';
import axiosInstance from '~util/axios';
import { RouteType, routes } from '../../../Routes';
import useWalletStore from '~store/WalletStore';
import { shallow } from 'zustand/shallow';
import DrawContext from '~context/DrawContext';
import useBalanceStore from '~store/useBalanceStore';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		backgroundColor: theme.palette.background.default,
		borderRadius: theme.shape.borderRadius,
		padding: `${theme.spacing(1)} 0 !important`,
		'& input': {
			padding: `0 !important`
		}
	},
	input: {
		'&>div:hover': {
			border: '0.1px solid #4d4d4d !important'
		}
	}
}));

const PERMISSIONS_NAME_REF: { [key: string]: number } = {
	app: 0,
	addresses: 1,
	address: 1,
	packages: 4,
	package: 4,
	users: 2,
	user: 2,
	groups: 2,
	group: 2,
	rate: 6,
	label: 3,
	pickup: 5,
	dispersion: 7,
	disperse: 7,
	business: 8,
	billing: 8
};

const PERMISSIONS_VALUE_REF: { [key: string]: number } = {
	canRead: 1,
	canCreateUpdateDelete: 2,
	none: 0
};

/**
 * This component is used when an Agent behaves like a customer
 */
interface Props {
	disabled?: boolean;
	setReset?: React.Dispatch<React.SetStateAction<boolean>>;
	matchRoutes?: boolean;
}

const CustomerSelector = ({ disabled, setReset, matchRoutes }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const query = useQuery();
	const history = useHistory();
	const location = useLocation();

	const { simulatedUser, simulatedCustomer, isTouchedSimulatedUser } = useSelector(
		(store: RootStore) => store.simulatedUser
	);
	const { currentFlow } = useSelector((state: RootStore) => state.flow);
	const { setAvailableBalance, setNotification } = useContext(DrawContext);

	const [customers, setCustomers] = useState<CustomerDetails[]>([]);
	const [customerUsers, setCustomerUsers] = useState<UserDetails[]>(
		simulatedCustomer ? simulatedCustomer.users : []
	);

	const [searchQuery, setSearchQuery] = useState<string>();
	const [debounceCustomer] = useDebounce(searchQuery, 200);

	const setCurrentBusiness = useWalletStore((state) => state.setCurrentBusiness, shallow);
	const { customerHasBalance } = useBalanceStore();

	const currentRoute = useMemo(
		() =>
			routes
				.filter((item) => item.path !== '/')
				?.find((route) => {
					const path = route.path?.includes(':')
						? route.path?.slice(0, route.path.indexOf(':') - 1)
						: route.path;
					return history.location.pathname?.includes(path);
				}),
		[history.location.pathname]
	);

	const isFormWithAutocompleteFields = useMemo(
		() => currentRoute?.path.includes('manual'),
		[currentRoute]
	);

	useEffect(() => {
		(async () => {
			if (!debounceCustomer) return;
			try {
				const { data } = await axios.get(`/api/customers?query=${debounceCustomer}`);
				setCustomers(data.data);
			} catch (error) {
				console.error('customer selector index error', error);
			}
		})();
	}, [debounceCustomer]);

	// Fetch customer balance
	const getCustomerBalance = useCallback(
		async (customer: CustomerDetails) => {
			try {
				const { data } = await axiosInstance.get(
					`/api/dispersion/balance?customer_id=${customer.id}`
				);
				setAvailableBalance(data.remaining);
			} catch (error) {
				setNotification({
					message: 'Hubo un error al obtener el saldo disponible del cliente',
					severity: 'error'
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setAvailableBalance]
	);

	// Fetch services and set customer
	const getCustomer = useCallback(
		async (customer: CustomerDetails) => {
			try {
				const { data } = await axiosInstance.get(`/api/customer/${customer.id}`);
				dispatch(setSimulatedCustomer({ ...customer, ...data }));
				if (customerHasBalance(data)) {
					await getCustomerBalance(data);
				}
			} catch (error) {
				dispatch(setSimulatedCustomer(customer));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getCustomerBalance]
	);

	// Get user for a customer
	const getCustomerUsers = useCallback(async (customerId: number) => {
		try {
			const { data } = await axiosInstance.get(`/api/users?customer_id=${customerId}`);
			const usersWithPermissions = usersPermissions(data.data, currentRoute);
			setCustomerUsers(usersWithPermissions);
		} catch (error) {
			console.error(error);
			setCustomerUsers([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Fetch
	const getUserBalance = useCallback(
		async (user: UserDetails) => {
			try {
				const { data } = await axiosInstance.get(
					`/api/dispersion/balance/users?customer_id=${simulatedCustomer?.id}`
				);
				const userBalance = data.data?.find((item: any) => item.user_id === user.id)?.remaining;
				setAvailableBalance(userBalance ?? 0);
			} catch (error) {
				setNotification({
					message: 'Hubo un error al obtener el saldo disponible del usuario',
					severity: 'error'
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[simulatedCustomer, setAvailableBalance]
	);

	// Add to query params the current simulated user
	useEffect(() => {
		if (!simulatedUser || !!matchRoutes) {
			return;
		}
		const params = appendOrReplaceValueInUrlParams(query, 'user_id', simulatedUser.id);
		history.replace(`${history.location.pathname}?${params}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [simulatedUser, history.location.pathname, matchRoutes]);

	useEffect(() => {
		if (!!simulatedUser && !simulatedCustomer?.id) {
			const customerData = customers.find((item) => item.id === simulatedUser.customer?.id);
			customerData && dispatch(setSimulatedCustomer(customerData));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [simulatedUser, simulatedCustomer, customers]);

	useEffect(() => {
		if (!customerUsers?.find((item) => item.id === simulatedUser?.id)) {
			dispatch(setSimulatedUser(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerUsers, simulatedUser?.id]);

	useEffect(() => {
		if (!simulatedCustomer || !!matchRoutes) {
			return;
		}
		const params = appendOrReplaceValueInUrlParams(query, 'customer_id', simulatedCustomer.id);
		history.replace(`${history.location.pathname}?${params}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [simulatedCustomer, history.location.pathname, matchRoutes]);

	// Check users permissions for route
	const usersPermissions = useCallback((users: UserDetails[], route?: RouteType) => {
		return users?.filter((item: UserDetails) => {
			const routeItemParts = route?.permissionKey.split('.') ?? [];
			const permissionModule = PERMISSIONS_NAME_REF[routeItemParts[1] ?? 'app'];
			const permissionType = PERMISSIONS_VALUE_REF[routeItemParts[0] ?? 'none'];

			return parseInt(item.permissions[permissionModule]) >= permissionType;
		});
	}, []);

	// Set only users with route permissions when location changes
	useEffect(() => {
		if (!simulatedCustomer) {
			setCustomers([]);
			return;
		}
		if (simulatedCustomer?.modality === 'sc') {
			setCustomerUsers(simulatedCustomer?.users);
		} else {
			const usersWithPermissions = usersPermissions(simulatedCustomer?.users, currentRoute);
			usersWithPermissions && setCustomerUsers(usersWithPermissions);
		}
	}, [currentRoute, simulatedCustomer, usersPermissions]);

	useEffect(() => {
		if (!!simulatedCustomer?.id && !customerUsers?.length) {
			getCustomerUsers(simulatedCustomer.id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [simulatedCustomer, getCustomerUsers]);

	if (!!matchRoutes) {
		return <div></div>;
	}

	return (
		<Grid
			container
			alignItems='center'
			justifyContent='center'
			spacing={2}
			className={classes.container}
		>
			<Grid item xs={12} sm={3} lg={4}>
				<Autocomplete
					disabled={disabled || (isFormWithAutocompleteFields && !!simulatedCustomer)}
					size='small'
					selectOnFocus
					noOptionsText={!searchQuery ? 'Escribe para buscar...' : 'Sin opciones'}
					options={!searchQuery && !simulatedCustomer?.id ? [] : customers}
					getOptionLabel={(option) => option.business_name.toUpperCase()}
					getOptionSelected={(option, value) => value && option.id === value.id}
					value={simulatedCustomer}
					onChange={async (_: any, value: CustomerDetails | null) => {
						if (value) {
							const response = await axiosInstance.get(`/api/users?customer_id=${value.id}`);

							// Set only users with current route permissions
							const usersWithPermissions = usersPermissions(response.data.data, currentRoute);
							setCustomerUsers(usersWithPermissions);
							await getCustomer(value);

							if (setReset) {
								setReset(false);
							}
						} else {
							// clear customer users
							setCustomerUsers([]);
							setAvailableBalance(null);
							if (setReset) {
								setReset(true);
							}
							dispatch(setSimulatedCustomer(value));
							setCurrentBusiness(null);
						}
						if (currentFlow === 'send') {
							dispatch(resetSend());
						} else if (currentFlow === 'rate') {
							dispatch(resetRate());
						}
						dispatch(setSimulatedUser(null));
					}}
					renderInput={(params) => (
						<Grid container alignItems='center' justifyContent='center'>
							<Grid item xs={12}>
								<TextField
									{...params}
									variant='outlined'
									error={isTouchedSimulatedUser && !simulatedCustomer}
									className={classes.input}
									placeholder='Empresa'
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
							</Grid>
						</Grid>
					)}
					PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
				/>
			</Grid>

			{/* Selected UserForm */}
			<Grid item xs={12} sm={3} lg={4}>
				{location.pathname !== '/pagos' && (
					<Autocomplete
						size='small'
						disabled={disabled || (isFormWithAutocompleteFields && !!simulatedUser)}
						selectOnFocus
						options={customerUsers || []}
						getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
						getOptionSelected={(option, value) => option.id === value.id}
						value={simulatedUser}
						onChange={async (_, value) => {
							if (value) {
								// TODO should be part of the get customer
								try {
									const result = await axios.get(`/api/user/${value.id}`);
									dispatch(setSimulatedUser(result.data));
									if (setReset) {
										setReset(false);
									}
									if (result.data?.customer?.balance) {
										await getUserBalance(value);
									}
								} catch (error) {
									dispatch(setSimulatedUser(null));
								}
							} else {
								dispatch(setSimulatedUser(null));
								setAvailableBalance(null);
								if (setReset) {
									setReset(true);
								}
							}
						}}
						renderInput={(params) => (
							<Grid container alignItems='center' justifyContent='center'>
								<Grid item xs={12}>
									<TextField
										{...params}
										variant='outlined'
										error={isTouchedSimulatedUser && !simulatedUser}
										// helperText={
										// 	isTouchedSimulatedUser && !simulatedUser ? 'Selecione un usuario' : ''
										// }
										className={classes.input}
										placeholder='Usuario'
									/>
								</Grid>
							</Grid>
						)}
						PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default CustomerSelector;
