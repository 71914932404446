import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles, StylesProvider } from '@mui/styles';
import { ThemeProvider } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { CssBaseline } from '@mui/material';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Routes from './Routes';
import axiosInstance, { instanceRate, instancePayment } from './util/axios';
import LogRocket from 'logrocket';
import { shallow } from 'zustand/shallow';

// Drawers
import LDrawer from './components/Navigation/LDrawer';
import TopNav from './components/Navigation/TopNav/TopNav';
import RDrawer from './components/Navigation/RDrawer';
import SnackBarToast from './components/Navigation/SnackBarToast';

// Auth
import SignInPage from './pages/auth/SignInPage';
import CreatePasswordPage from './pages/auth/CreatePasswordPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';

import { useTheme } from './hooks/useTheme';
import AuthContext from './context/AuthContext';
import DrawContext from './context/DrawContext';
import LoaderPage from './pages/loader/LoaderPage';
import { UserDetails } from './store/actions/ActionTypes';
import { END_POINT_WS, LOGROCKET_ENABLE, LOGROCKET_KEY } from './config';
import useReportExportsStore from '~store/newstore/useReportExportsStore';
import useNotificationStore from '~store/newstore/useNotificationStore';
import ShipmentTracking from '~pages/tracking/ShipmentTracking';
import { io } from 'socket.io-client';
import ServerUpdatedModal from '~components/Modals/ServerUpdatedModal';
import HomeNotificationModal, { NotificationItem } from '~components/Modals/HomeNotificationModal';
import TutorialsModal from '~components/Modals/TutorialsModal';
import TutorialButton from '~components/Buttons/TutorialButton';
import { TUTORIALS_LIST_AUTH_USER, TUTORIALS_LIST_UNAUTH_USER } from '~util/tutorials';
import { StyledEngineProvider } from '@mui/material';
import './MuiClassNameSetup';

const useStyles = makeStyles((theme) => {
	return {
		mainContainer: {
			marginTop: 80,
			marginBottom: 80
		}
	};
});

const App: React.FC = () => {
	const classes = useStyles();

	const { themeV4, currentTheme, toggleTheme, generateClassName } = useTheme(false);
	const { setIsCheckingIsAuth, setAuth, setCurrentUser, isCheckingIsAuth, isAuth, currentUser } =
		useContext(AuthContext);
	const { rCardStatus } = useContext(DrawContext);
	const [reportExports, removeReportExports] = useReportExportsStore(
		(state) => [state.reportExports, state.removeReportExports],
		shallow
	);
	const [setNotificationQueue, clearNotificationQueue] = useNotificationStore(
		(state) => [state.setNotificationQueue, state.clearNotificationsQueue],
		shallow
	);

	const [showServerUpdateAlert, setShowServerUpdateAlert] = useState(false);
	const [showDispersionAlert, setShowDispersionAlert] = useState(false);
	const [notificationAlert, setNotificationAlert] = useState<NotificationItem | null>(null);

	const handleRealod = () => {
		window.location.reload();
	};

	// Confirm read notification
	const handleReadNotification = useCallback(async () => {
		setShowDispersionAlert(false);
		setNotificationAlert(null);
	}, []);

	const checkForNewerVersion = async () => {
		const token = localStorage.getItem('auth');
		if (token) {
			try {
				axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
				const response = await axiosInstance.get('/api/version');
				const lastVersion = response.data.version;
				const currentVersion = localStorage.getItem('version');

				// if there is no current version, we set the last version and force to update just in case
				// if there is a current version, we compare it with the last version and force to update just in case
				if (!currentVersion || currentVersion !== lastVersion) {
					setShowServerUpdateAlert(true);
					localStorage.setItem('version', lastVersion);
				}
				// so it doesn't break the whole page in case of a network error
			} catch (err) { }
		}
	};

	useEffect(() => {
		const SECOND = 1_000;
		const MINUTE = SECOND * 60;
		const CHECK_EVERY = MINUTE * 10;
		const id = setInterval(checkForNewerVersion, CHECK_EVERY);

		return () => {
			clearInterval(id);
		};
	}, []);

	useEffect(() => {
		const handler = () => {
			if (document.visibilityState === 'visible') {
				checkForNewerVersion();
			}
		}

		document.addEventListener('visibilitychange', handler)

		return () => {
			document.removeEventListener('visibilitychange', handler);
		}
	}, []);

	useEffect(() => {
		const socket = io(END_POINT_WS || '');

		socket.on('serverUpdated', (version) => {
			// set the version updated from server, stop from double update on reload
			localStorage.setItem('version', version);
			setShowServerUpdateAlert(true);
		});

		socket.on(`notifications_${currentUser.id}`, (...newDispersions) => {
			if (!Array.isArray(newDispersions) || !newDispersions?.length) return;
			setShowDispersionAlert(true);
			setNotificationAlert(newDispersions[0]);
		});

		return () => {
			socket.disconnect();
		};
	}, [currentUser.id]);

	// Validate Token
	useEffect(() => {
		(async () => {
			const token = localStorage.getItem('auth');
			if (token) {
				try {
					axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
					instanceRate.defaults.headers.common['Authorization'] = `Bearer ${token}`;
					instancePayment.defaults.headers.common['Authorization'] = `Bearer ${token}`;
					const userDetails = await axiosInstance.get('/api/user');
					setCurrentUser(userDetails.data as UserDetails);
					setAuth(true);

					// Check last available version
					const response = await axiosInstance.get('/api/version');
					const lastVersion = response.data.version;
					const currentVersion = localStorage.getItem('version');

					// if there is no current version, we set the last version and force to update just in case
					// if there is a current version, we compare it with the last version and force to update just in case
					if (!currentVersion || currentVersion !== lastVersion) {
						setShowServerUpdateAlert(true);
						localStorage.setItem('version', lastVersion);
					}
				} catch (error) {
					localStorage.removeItem('auth');
					setAuth(false);
					axiosInstance.defaults.headers.common['Authorization'] = undefined;
					instanceRate.defaults.headers.common['Authorization'] = undefined;
					instancePayment.defaults.headers.common['Authorization'] = undefined;
					clearNotificationQueue();
				}
			}
			setIsCheckingIsAuth(false);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuth, setIsCheckingIsAuth]);

	// Queue for reports
	useEffect(() => {
		const intervalId = setInterval(async () => {
			if (reportExports.length === 0) {
				clearInterval(intervalId);
			}
			for (const report of reportExports) {
				try {
					const url =
						report?.type === 'invoices'
							? `/reports/invoices/${report.id}`
							: `/reports/labels/${report.id}`;
					const status = await instanceRate.get(url);
					if (status.data.status === 'done') {
						window.open(report.url, '_blank');
						setNotificationQueue({
							message: `El Reporte ${
								new Date().toISOString().split('T')[0]
							} esta listo. Da click para verlo`,
							date: new Date(),
							callBack: () => {
								window.open(report.url, '_blank');
							}
						});
						removeReportExports(report.spreadsheet_id);
					}
				} catch (e) {
					console.error(e);
					setNotificationQueue({
						message: `El Reporte ${
							new Date().toISOString().split('T')[0]
						} no se pudo procesar, intente más tarde`,
						date: new Date()
					});
					clearInterval(intervalId);
					removeReportExports(report.spreadsheet_id);
				}
			}
		}, 1000 * 5); // in milliseconds

		return () => clearInterval(intervalId);
	}, [reportExports, setNotificationQueue, removeReportExports]);

	// Enable logRocket
	useEffect(() => {
		if (currentUser?.id !== 0 && LOGROCKET_ENABLE) {
			LogRocket.init(LOGROCKET_KEY);
			LogRocket.identify(currentUser.id.toString(), {
				email: currentUser.email,
				name: currentUser.name
			});
		}
	}, [currentUser]);

	// Prospect documentation step
	// useEffect(() => {
	// 	// TODO: other condition
	// 	if (currentUser.role === UserRole.PROSPECT) {
	// 		history.push('/prospecto/documentos');
	// 	}
	// }, [currentUser.role]);

	// Shows the user a load while checking
	if (isCheckingIsAuth) {
		return (
			<StyledEngineProvider injectFirst>
				<StylesProvider generateClassName={generateClassName}>
					<ThemeProvider theme={themeV4}>
						<CssBaseline />
						<LoaderPage />
					</ThemeProvider>
				</StylesProvider>
			</StyledEngineProvider>
		);
	}

	// If is not auth, the pages to login or singup
	if (!isAuth) {
		return (
			<StyledEngineProvider injectFirst>
				<StylesProvider generateClassName={generateClassName}>
					<ThemeProvider theme={themeV4}>
						{/* Toasts (snackbar)*/}
						<SnackBarToast />
						<CssBaseline />
						<Router>
							{/* Tutorial button and modal */}
							<TutorialButton tutorialList={TUTORIALS_LIST_UNAUTH_USER} />
							<TutorialsModal tutorialList={TUTORIALS_LIST_UNAUTH_USER} />
							<Switch>
								<Route path='/auth' exact component={SignInPage} />
								<Route path='/tracking/:id' exact component={ShipmentTracking} />
								<Route
									path='/validate-account/:validateToken'
									exact
									component={CreatePasswordPage}
								/>
								<Route path='/newpassword/:validateToken' exact component={CreatePasswordPage} />
								<Route path='/forgotpassword' exact component={ForgotPasswordPage} />
								<Redirect to='/auth' />
							</Switch>
						</Router>
					</ThemeProvider>
				</StylesProvider>
			</StyledEngineProvider>
		);
	}

	// He or She is an authenticated user
	return (
		<StyledEngineProvider injectFirst>
			<StylesProvider generateClassName={generateClassName}>
				<ThemeProvider theme={themeV4}>
					<CssBaseline />
					<Router>
						<ServerUpdatedModal
							isOpen={showServerUpdateAlert}
							onClose={() => setShowServerUpdateAlert(false)}
							onConfirm={handleRealod}
						/>
						<HomeNotificationModal
							isOpen={showDispersionAlert}
							onFinish={handleReadNotification}
							notification={notificationAlert}
						/>

						{/* Tutorial button and modal */}
						<TutorialButton tutorialList={TUTORIALS_LIST_AUTH_USER} />
						<TutorialsModal tutorialList={TUTORIALS_LIST_AUTH_USER} />

						{/* Toasts (snackbar)*/}
						<SnackBarToast />
						{/* Top Nav Bar */}
						<TopNav currentTheme={currentTheme} toggleTheme={toggleTheme} />
						{/* Left Menu */}
						<LDrawer />
						{/* Rigth Menu */}
						{rCardStatus !== 'HIDDEN' && <RDrawer />}
						{/* Main Layout */}
						<Grid container justifyContent='flex-start' alignItems='flex-start'>
							{/* Space for Left Draw */}
							<Grid item xs={1} />
							{/* Main Layout */}
							<Grid
								item
								xs={10}
								//md={10}
								lg={rCardStatus !== 'HIDDEN' ? 8 : 10}
								container
								direction='row'
								justifyContent='center'
								alignItems='flex-start'
								className={classes.mainContainer}
							>
								<Routes />
							</Grid>
							{/* Space for Right Draw */}
							<Grid item xs={1} />
							{/* Space for Right Draw */}
							<Grid item xs={1} />
						</Grid>
					</Router>
				</ThemeProvider>
			</StylesProvider>
		</StyledEngineProvider>
	);
};

export default App;
