import React, { useEffect, useMemo, useState } from 'react';
// hooks and tools
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import 'moment/locale/es';
import { FollowUpLabelStatus } from '~util/cellStatusColor';
import useShipmentDetails from './useShipmentDetails';
import { useSelector } from 'react-redux';
import usePermissions from '~hooks/usePermissions';
import { useHistory, useLocation } from 'react-router-dom';
import useQuery from '~hooks/useQuery';
import { CARRIERS } from '~util/logos';
import { Edit, Save } from 'react-feather';
// store and context
import { useDispatch } from 'react-redux';
import { setDestinationMapCoordinates, setMapCoordinates } from '~store/actions';
import {
	AddressRequest,
	BillingSchema,
	NotificationType,
	Package,
	ShipmentCompensation,
	ShipmentModality,
	ShipmentSendResponse
} from '~store/actions/ActionTypes';
import { RootStore } from '~store/store';

// components
import CarrierService from '~components/Tables/CarrierService';
import TrackingContainer from '../_TrakingContainer';
import LabelContainer from '../_LabelContainer';
import AddressSummaryCard from '../../Cards/AddressSummaryCard';
import StatusCell from '../../Tables/_StatusCell';
import StaticMap from '~components/Maps/StaticMap';
import NotificationRow, {
	NotificationsContactsFields
} from '~components/Forms/Notifications/NotificationRow';
import DropdownPaper from '~components/Forms/DropdownPaper';
import SaveButton from '~components/Tables/Controls/SaveButton';
import PackagesDetail from './PackagesDetail';
import EditLabelFields from './EditLabelFields';
import FilesLabelManual, { FileLink } from './FilesLabelManual';
import ConfirmActionDialog from '../ConfirmActionDialog';

// styles and icons
import {
	Typography,
	TextField,
	CircularProgress,
	Grid,
	LinearProgress,
	Checkbox,
	FormControlLabel,
	Button,
	List
} from '@mui/material';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { Autocomplete } from '@material-ui/lab';
import { Truck, Send as SendIcon } from 'react-feather';
import { useStyles } from './styles';

const NOTIFICATION_FIELDS = [
	{
		alias: '',
		phone_number: '',
		guide_status: '',
		shipping_mode: '',
		active: false
	},
	{
		alias: '',
		phone_number: '',
		guide_status: '',
		shipping_mode: '',
		active: false
	}
];

interface Props {
	data: ShipmentSendResponse | null;
	isManual?: boolean;
	isEditLabel: boolean;
}

/**
 * This is the modal to see the detials of a label
 */

const ShipmentDetailsContent: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { errors, control, handleSubmit, getValues, setError, clearErrors } = useForm();
	const history = useHistory();
	const { can, isRoot } = usePermissions();

	const {
		updatedData,
		setUpdatedData,
		fetchGuideDetails,
		businessList,
		loadingData,
		loadingPDF,
		errorPDF,
		pdf,
		selectedBusiness,
		setSelectedBusiness,
		putLabelBusiness,
		addCharges,
		confirmReused,
		loadingSubmit
	} = useShipmentDetails();
	const [loadingPostEdit, setLoadingPostEdit] = useState(false);
	const [immediateSchema, setImmediateSchema] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState<
		'billing' | 'charges' | 'reused' | null
	>(null);
	const [isEditing, setIsEditing] = useState(false);

	const { simulatedCustomer } = useSelector((state: RootStore) => state.simulatedUser);
	const canHaveBusinessActions = can('canRead.business');
	const location: { state: { carrier: string; id: string } } = useLocation();
	const query = useQuery();
	const dataWithPackagesId = useMemo(() => {
		const packages = updatedData?.packages?.map((subItem: Package) => ({
			...subItem,
			id: Date.now()
		}));
		return { ...updatedData, packages };
	}, [updatedData]);

	const onSubmit = async (data: {
		modality?: ShipmentModality;
		compensation?: ShipmentCompensation;
		notes?: string;
		notification_contacts?: {};
	}) => {
		setLoadingPostEdit(true);
		const payload: any = {
			compensation: data?.compensation || undefined,
			modality: data?.modality || undefined,
			notes: data?.notes || undefined
		};

		putLabelBusiness({
			payload,
			cb: () => {
				history.push('/envios');
				setLoadingPostEdit(false);
			}
		});
	};

	const deliveredTime = useMemo(
		() =>
			updatedData?.events?.find((item) => item.description === FollowUpLabelStatus.DELIVERED)
				? updatedData?.events?.find((item) => item.description === FollowUpLabelStatus.DELIVERED)
						?.createdAt
				: '',
		[updatedData]
	);

	// fetch details when open
	useEffect(() => {
		const paramId = query.get('id');
		const carrier = query.get('carrier');
		(async () =>
			fetchGuideDetails(
				props.data,
				paramId ?? location.state?.id,
				carrier ?? location.state?.carrier,
				props.isManual
			))();

		return () => {
			setUpdatedData(null);
			setSelectedBusiness(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		props.data,
		props.isManual,
		setSelectedBusiness,
		setUpdatedData,
		fetchGuideDetails,
		location
	]);

	const shouldDisplayPdf = useMemo(() => {
		return ![FollowUpLabelStatus.EXPIRED, FollowUpLabelStatus.CANCELLED].includes(
			updatedData?.follow_up as FollowUpLabelStatus
		);
	}, [updatedData]);

	const formatUrlParameters = (type: 'origin' | 'destination') => {
		const item = type === 'origin' ? updatedData?.origin : updatedData?.destination;
		return `${item?.address?.replaceAll(' ', '+')}+${item?.city.replaceAll(
			' ',
			'+'
		)}+${item?.state.replaceAll(' ', '+')}`;
	};

	// set co0rdinates
	useEffect(() => {
		const destinationCoordinates = !!updatedData?.destination.coordinates
			? (updatedData?.destination.coordinates as { lat: number; lng: number })
			: {
					lat: updatedData?.destination.latitude ?? 0,
					lng: updatedData?.destination.longitude ?? 0
			  };

		const originCoordinates = !!updatedData?.origin.coordinates
			? (updatedData?.origin.coordinates as { lat: number; lng: number })
			: { lat: updatedData?.origin.latitude ?? 0, lng: updatedData?.origin.longitude ?? 0 };

		dispatch(setMapCoordinates(destinationCoordinates));
		dispatch(setDestinationMapCoordinates(originCoordinates));
		return () => {
			dispatch(setMapCoordinates(null));
			dispatch(setDestinationMapCoordinates(null));
		};
	}, [updatedData, dispatch]);

	const createdAtDate = useMemo(
		() => moment(updatedData?.created_at, 'LL').format('LL') ?? '',
		[updatedData]
	);

	if (loadingData) {
		return (
			<Grid container justifyContent='center' alignContent='center' style={{ minHeight: '70vh' }}>
				<CircularProgress />
			</Grid>
		);
	}
	if (
		!updatedData ||
		(canHaveBusinessActions && updatedData.business_id && !businessList) ||
		(canHaveBusinessActions &&
			!selectedBusiness &&
			!!simulatedCustomer?.id &&
			!!updatedData.business_id)
	) {
		return (
			<Grid container justifyContent='center' alignContent='center' style={{ minHeight: '70vh' }}>
				<CircularProgress />
			</Grid>
		);
	}

	return (
		<Grid item container>
			{/* LEFT SIDE: */}
			<Grid item xs={12} sm={4} container direction='column' className={classes.leftSide}>
				<div className={classes.box}>
					<div className={clsx(classes.carrierBox)}>
						<CarrierService carrier={updatedData.carrier} />
						<div className={classes.guideIdBox}>
							<Typography style={{ color: '#57BBD8' }}>Código de rastreo</Typography>
							<Typography style={{ fontWeight: 400, paddingTop: '4px' }} variant='h6'>
								{updatedData?.tracking_number}
							</Typography>
						</div>
					</div>

					<div className={clsx(classes.guideData)}>
						<p>
							<Truck />
							Servicio:
							<span>{updatedData.service_description}</span>
						</p>
						<p>
							<EventAvailableIcon />
							Creación de guía:
							<span>{createdAtDate}</span>
						</p>
						<p>
							<SendIcon />
							N° de Guía:<span>{updatedData.parcel_number}</span>
						</p>
					</div>
				</div>
				{/* Razón social */}
				{canHaveBusinessActions && (
					<div className={classes.box}>
						<Autocomplete
							defaultValue={selectedBusiness}
							fullWidth
							selectOnFocus
							options={businessList?.filter((item) => item.status !== 'disabled') || []}
							getOptionLabel={(option) => option.business_name}
							getOptionSelected={(option, value) => option.business_name === value.business_name}
							onChange={(e, value) => {
								if (!value) return;
								const payload = { business_id: value.id };
								putLabelBusiness({ payload });
								setUpdatedData({
									...updatedData,
									business_id: value.id
								});
							}}
							renderInput={(params) => (
								<Grid container alignItems='center' justifyContent='center'>
									<Grid item xs={12}>
										<TextField {...params} label='Facturar a:' variant='filled' />
									</Grid>
								</Grid>
							)}
							disabled={!updatedData?.can_edit_business}
							PaperComponent={(paperProps) => <DropdownPaper>{paperProps.children}</DropdownPaper>}
						/>
					</div>
				)}
				{isRoot && updatedData.billing === BillingSchema.IMMEDIATE && (
					<div className={classes.box}>
						<div>
							{updatedData?.immediate_billing || immediateSchema ? (
								<FormControlLabel
									control={
										<Checkbox disabled style={{ marginRight: '8px' }} color='primary' checked />
									}
									label={<div>Facturación Inmediata</div>}
								/>
							) : (
								<Button
									className={clsx(classes.btns, classes.largeBtn, classes.immediateBillingBtn)}
									disabled={
										!updatedData.business_id || !updatedData?.can_edit_business || loadingPostEdit
									}
									color='primary'
									variant='contained'
									onClick={() => {
										setOpenConfirmDialog('billing');
									}}
								>
									Facturación Inmediata
								</Button>
							)}
						</div>
					</div>
				)}
				{isRoot && (
					<div className={classes.box}>
						<div>
							<Button
								className={clsx(classes.btns, classes.largeBtn)}
								disabled={
									!updatedData.business_id ||
									!updatedData?.can_edit_business ||
									updatedData?.immediate_billing ||
									loadingPostEdit
								}
								color='primary'
								variant='contained'
								onClick={() => {
									setOpenConfirmDialog('charges');
								}}
							>
								Agregar cargos al reporte
							</Button>
						</div>
					</div>
				)}
				{isRoot && updatedData?.notified_reused && (
					<div className={classes.box}>
						<div>
							<Button
								className={classes.btns}
								color='primary'
								variant='contained'
								onClick={() => {
									setOpenConfirmDialog('reused');
								}}
							>
								Confirmar Reutilizada
							</Button>
						</div>
					</div>
				)}
				{/* Estatus */}
				<Grid container className={clsx(classes.box, classes.boxWithBorder)}>
					<Grid item container alignItems='center'>
						<Grid item xs={6}>
							<Typography>Estatus</Typography>
						</Grid>
						<Grid item xs={6}>
							<StatusCell status={updatedData.follow_up} />
						</Grid>
					</Grid>
					{deliveredTime && (
						<Grid item container justifyContent='flex-end' style={{ padding: '8px' }}>
							<Typography variant='caption'>{deliveredTime}</Typography>
						</Grid>
					)}
				</Grid>
				{/* Firma guía entregada */}
				{!!updatedData?.sign_delivery &&
					updatedData?.follow_up === FollowUpLabelStatus.DELIVERED && (
						<Grid container className={clsx(classes.box, classes.boxWithBorder)}>
							<Typography>Evidencia Entrega</Typography>
							<Grid item container alignItems='center' justifyContent='center'>
								{updatedData?.carrier === CARRIERS.FEDEX ||
								updatedData?.carrier === CARRIERS.DHL ? (
									<List className={classes.signFile}>
										<FileLink
											file={{
												path: 'Descarga Evidencia Entrega',
												url: `data:application/${
													updatedData?.carrier === CARRIERS.FEDEX ? 'img' : 'pdf'
												};base64,${updatedData?.sign_delivery}`,
												type: ''
											}}
											downloadName={`Descarga-Evidencia-Entrega.${
												updatedData?.carrier === CARRIERS.FEDEX ? 'jpg' : 'pdf'
											}`}
										/>
									</List>
								) : (
									<Grid item container>
										<img
											src={`data:application/img;base64,${updatedData?.sign_delivery}`}
											alt='Firma transportista'
											className={classes.signImg}
										/>
										{!!updatedData?.received_by_name && (
											<span style={{ paddingTop: '8px' }}>
												Recibió {updatedData?.received_by_name}
											</span>
										)}
									</Grid>
								)}
							</Grid>
						</Grid>
					)}
				{/* Historial */}
				<div className={clsx(classes.box, classes.boxWithBorder)}>
					<Typography>Historial</Typography>
					<TrackingContainer
						followUp={updatedData.follow_up}
						service={updatedData.service_description}
						carrier={updatedData.carrier}
						events={
							['redpack', 'dhl', 'estafeta', 'fedex', 'paquetexpress', 'sendex'].includes(
								updatedData.carrier
							)
								? updatedData.events
								: undefined
						}
					/>
				</div>
			</Grid>
			{/* RIGHT SIDE: */}
			<Grid item xs={12} sm={8} className={classes.rightSide}>
				{/* Paquetes */}
				{!!updatedData?.packages?.length && !!dataWithPackagesId?.packages?.length && (
					<PackagesDetail updatedData={dataWithPackagesId as ShipmentSendResponse} />
				)}

				{/* Notificaciones */}
				<Grid item className={classes.box} container>
					{!props.isEditLabel && (
						<Grid item className={classes.notificationBox} container>
							<Grid item container justifyContent='space-between'>
								<Typography variant='h4' className={classes.sectionTitle}>
									Notificaciones WhatsApp
								</Typography>
								<Button
									variant='contained'
									disabled={loadingPostEdit}
									className={classes.btns}
									onClick={async () => {
										setIsEditing((prev) => !prev);
										if (isEditing) {
											setLoadingPostEdit(true);
											const value = getValues();
											const contactIdShipper = updatedData?.notification_contacts?.find(
												(item) => item.type === NotificationType.SHIPPPER
											)?.contact_id;
											const contactIdRecipient = updatedData?.notification_contacts?.find(
												(item) => item.type === NotificationType.RECIPIENT
											)?.contact_id;

											const notifications = [
												{
													contact_id: contactIdShipper,
													active: value?.user_active,
													alias: value?.user_alias,
													phone_number: value?.user_phone_number,
													guide_status: value?.user_guide_status,
													type: NotificationType.SHIPPPER,
													shipping_mode: 'Este envío'
												},
												{
													contact_id: contactIdRecipient,
													active: value?.contact_active,
													alias: value?.contact_alias,
													phone_number: value?.contact_phone_number,
													guide_status: value?.contact_guide_status,
													type: NotificationType.RECIPIENT,
													shipping_mode: 'Este envío'
												}
											];
											await putLabelBusiness({
												payload: { notification_contacts: notifications },
												cb: () => {
													setLoadingPostEdit(false);
												}
											});
										}
									}}
									endIcon={!isEditing ? <Edit size={18} /> : <Save size={18} />}
								>
									{!isEditing ? 'Editar' : 'Actualizar'}
								</Button>
							</Grid>

							<Grid item container alignItems='center' className={classes.notificationBox}>
								<Grid item container alignItems='flex-end' spacing={2}>
									<Grid item xs={12} container>
										<Grid item md={1}></Grid>
										<Grid item md={3}>
											Avisar a
										</Grid>
										<Grid item md={4}>
											Estatus guía
										</Grid>
										<Grid item md={2}>
											WhatsApp
										</Grid>
									</Grid>

									<NotificationRow
										control={control}
										errors={errors}
										type='user'
										canEdit={isEditing}
										defaultValues={
											updatedData?.notification_contacts
												? updatedData?.notification_contacts?.every((item) => !!item.type)
													? updatedData?.notification_contacts?.find(
															(item) => item.type === NotificationType.SHIPPPER
													  )
													: updatedData.notification_contacts.find(
															(item: NotificationsContactsFields) => Boolean(item.user_id)
													  ) ??
													  updatedData.notification_contacts.find(
															(item: NotificationsContactsFields) =>
																item.alias?.toLowerCase() ===
																updatedData.origin?.contact?.name?.toLowerCase()
													  )
												: NOTIFICATION_FIELDS[0]
										}
										backgroundColor='inherit'
										setError={setError}
										clearErrors={clearErrors}
									/>
									<NotificationRow
										control={control}
										errors={errors}
										type='contact'
										canEdit={isEditing}
										defaultValues={
											updatedData?.notification_contacts
												? updatedData?.notification_contacts?.every((item) => !!item.type)
													? updatedData?.notification_contacts?.find(
															(item) => item.type === NotificationType.RECIPIENT
													  )
													: updatedData.notification_contacts?.every((item) =>
															// @ts-ignore
															Boolean(item?.contact_id)
													  )
													? updatedData.notification_contacts.find(
															(item: NotificationsContactsFields) =>
																item.alias?.toLowerCase() ===
																updatedData.destination?.contact?.name?.toLowerCase()
													  )
													: updatedData.notification_contacts.find(
															(item: NotificationsContactsFields) => Boolean(item.contact_id)
													  )
												: NOTIFICATION_FIELDS[1]
										}
										backgroundColor='inherit'
										setError={setError}
										clearErrors={clearErrors}
									/>
								</Grid>
							</Grid>
						</Grid>
					)}

					{/* Direcciones */}
					<Grid item container>
						<Grid item xs={6} className={classes.address}>
							<AddressSummaryCard
								showTitle
								type='Origen'
								address={updatedData?.origin as unknown as AddressRequest}
								onlyAddress
								carrier={updatedData?.carrier}
							/>
						</Grid>
						<Grid item xs={6} className={classes.address}>
							<AddressSummaryCard
								showTitle
								type='Destino'
								address={updatedData?.destination as unknown as AddressRequest}
								onlyAddress
								carrier={updatedData?.carrier}
							/>
						</Grid>
					</Grid>

					{/* Editar guía: campos de indemnización */}
					{props.isEditLabel && <EditLabelFields control={control} updatedData={updatedData} />}

					{/* Mapas */}
					{!props.isEditLabel && (
						<Grid item xs={12} container spacing={2} style={{ paddingBottom: '12px' }}>
							<Grid item xs={6}>
								{updatedData?.origin.latitude && (
									<StaticMap
										address={formatUrlParameters('origin')}
										latitude={updatedData?.origin.latitude}
										longitude={updatedData?.origin.longitude}
									/>
								)}
							</Grid>
							<Grid item xs={6} style={{ borderRadius: '8px' }}>
								{updatedData?.destination.latitude && (
									<StaticMap
										address={formatUrlParameters('destination')}
										latitude={updatedData?.destination.latitude}
										longitude={updatedData?.destination.longitude}
									/>
								)}
							</Grid>
						</Grid>
					)}

					{/* PDF */}
					{!props.isEditLabel &&
						(shouldDisplayPdf || (props.isManual && !!updatedData.files?.length)) && (
							<Grid item container justifyContent='center' className={classes.box}>
								<Grid item xs={12}>
									{!errorPDF ? (
										!loadingPDF ? (
											!props.isManual ? (
												<LabelContainer pdf={pdf} />
											) : (
												updatedData?.files?.map(
													(item) =>
														!item.type?.includes('excel') && (
															<embed
																style={{ margin: '6px 0', width: '100%' }}
																src={item.url}
																type={item.type}
																height='450px'
															/>
														)
												)
											)
										) : (
											<LinearProgress />
										)
									) : null}
								</Grid>
							</Grid>
						)}

					{/* Detalle guía manual: archivos complementarios */}
					{!!updatedData?.files?.length && <FilesLabelManual files={updatedData?.files} />}
				</Grid>
			</Grid>
			{props.isEditLabel && (
				<Grid
					item
					xs={12}
					container
					justifyContent='flex-end'
					style={{ padding: '40px 6px 16px 0px' }}
				>
					<SaveButton onClick={handleSubmit(onSubmit)} isLoading={loadingPostEdit} icon='save'>
						Guardar
					</SaveButton>
				</Grid>
			)}

			<ConfirmActionDialog
				isOpen={!!openConfirmDialog}
				onClose={() => setOpenConfirmDialog(null)}
				bodyText={
					openConfirmDialog === 'billing'
						? '¿Estás seguro de realizar facturación inmediata?'
						: openConfirmDialog === 'charges'
						? '¿Estás seguro de agregar cargos a esta guía?'
						: '¿Quieres confirmar que la guía fue reutilizada?'
				}
				cancelBtnLabel={openConfirmDialog === 'reused' ? 'No' : 'Cancelar'}
				confirmBtnLabel={openConfirmDialog === 'reused' ? 'Sí' : 'Confirmar'}
				handleClickConfirm={async () => {
					setLoadingPostEdit(true);
					if (openConfirmDialog === 'billing') {
						// Set immediate billing
						await putLabelBusiness({
							payload: { immediate_billing: true, business_id: updatedData?.business_id },
							cb: () => {
								setImmediateSchema(true);
								setUpdatedData({
									...updatedData,
									immediate_billing: true,
									business_id: updatedData?.business_id,
									billing: BillingSchema.IMMEDIATE
								});
							}
						});
					} else if (openConfirmDialog === 'charges') {
						// Add charges to report
						await addCharges();
					} else {
						// Set that the guide was reused
						await confirmReused(true, updatedData?.parcel_number);
					}
					setOpenConfirmDialog(null);
					setLoadingPostEdit(false);
				}}
				handleClickCancel={async () => {
					if (openConfirmDialog === 'reused') {
						// Set that the guide was not reused
						await confirmReused(false, updatedData?.parcel_number);
						setOpenConfirmDialog(null);
						return;
					}
					setOpenConfirmDialog(null);
				}}
				loading={loadingPostEdit || loadingSubmit}
			/>
		</Grid>
	);
};

export default ShipmentDetailsContent;
