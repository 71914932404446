import { FC, useCallback, useEffect } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import clsx from 'clsx';
// Hooks
import { useCardStyles } from '~hooks/useStyles';
import { useStyles } from './styles';
import useIncidentStore from '~store/IncidentStore';
import { useIncidentForm } from './useIncidentForm';
// Components
import { HeadingOne, HeadingTwo, TextOne } from '~components/Headings';
import AddressSummaryCard from '~components/Cards/AddressSummaryCard';
import SaveButton from '~components/Tables/Controls/SaveButton';
import IncidentRegister from './Forms/IncidentRegister';
import { AddButton } from '~components/Tables/Controls/AddButton';
import IncidentNotifications from './IncidentNotifications';
// Utils
import { AddressRequest, ShipmentSendResponse } from '~store/actions/ActionTypes';

/**
 * Create incident: summary
 */
const IncidentResume: FC = () => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const history = useHistory();

	const { control, errors, handleSubmit, getValues } = useForm();
	const { label, incidentType } = useIncidentStore();

	const { submitForm, isLoading } = useIncidentForm();

	const {
		fields: fieldsContacts,
		append: appendContact,
		remove: removeContact
	} = useFieldArray({
		control,
		name: 'notification_contacts',
		keyName: 'key'
	});

	const onSubmit = useCallback(
		async (data: { [key: string]: any }) => {
			await submitForm(data);
		},
		[submitForm]
	);

	useEffect(() => {
		if (!fieldsContacts.length) {
			appendContact({ id: '', name: '', type: '', phone_number: '' });
			appendContact({ id: '', name: '', type: '', phone_number: '' });
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container className={clsx([cardClasses.root, classes.container])}>
			<Grid item xs={12} container alignItems='center' spacing={1}>
				<Grid item>
					<ArrowLeft size='32' className={classes.close} onClick={() => history.goBack()} />
				</Grid>
				<Grid item>
					<HeadingOne text='Resumen del Incidente' />
				</Grid>
			</Grid>

			{/* GENERAL DATA SECTION */}
			<Grid item xs={12} className={clsx([cardClasses.card, classes.card])}>
				<HeadingTwo text={'Datos de la guía'} />
				<IncidentRegister
					control={control}
					errors={errors}
					defaultValues={{ ...(label as ShipmentSendResponse), incident_type: incidentType }}
					isResume
				/>
			</Grid>

			{/* ADDRESS SECTION */}
			<Grid item xs={12} container className={clsx([cardClasses.card, classes.card])}>
				<Grid item xs={12}>
					<HeadingTwo text='Direcciones y contacto' style={{ padding: 0 }} />
				</Grid>
				<Grid container item xs={12} justifyContent='center' alignItems='flex-start'>
					<Grid item xs={12} md={5}>
						<AddressSummaryCard showTitle type='Origen' address={label?.origin as AddressRequest} />
					</Grid>
					<Grid item xs={12} md={5}>
						<AddressSummaryCard
							showTitle
							type='Destino'
							address={label?.destination as AddressRequest}
						/>
					</Grid>
				</Grid>
			</Grid>

			{/* CONTACTS SECTION */}
			<Grid container spacing={1} alignItems='center' className={classes.spacing}>
				<Grid item xs={12} container justifyContent='space-between' alignItems='center'>
					<Grid item className={classes.spacing}>
						<HeadingTwo text='Notificación de Estatus' />
						<TextOne text='Los contactos que se indiquen cada avance del reporte' />
					</Grid>
					<AddButton
						onClick={() => appendContact({ contact: 'notification_contacts' })}
						redirectTo='/'
					/>
				</Grid>
				<IncidentNotifications
					fields={fieldsContacts}
					control={control}
					remove={removeContact}
					errors={errors}
					multiple
					getValues={getValues}
				/>
			</Grid>

			<Grid
				item
				xs={12}
				className={clsx([cardClasses.card, classes.card])}
				container
				justifyContent='flex-end'
			>
				<SaveButton
					onClick={handleSubmit(onSubmit)}
					disabled={isLoading}
					isLoading={isLoading}
					icon='create-guide'
				>
					Generar Incidente
				</SaveButton>
			</Grid>
		</Grid>
	);
};

export default IncidentResume;
