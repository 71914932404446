import useSWR from 'swr';
import { useState, useContext, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useQuery from './useQuery';
import axios from '../util/axios';
import { GridCallbackDetails } from '@mui/x-data-grid';
import DrawContext from '~context/DrawContext';
import { useSelector } from 'react-redux';
import { RootStore } from '~store/store';
import usePermissions from './usePermissions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function logger(useSWRNext: any) {
	return (key: string, fetcher: () => void, config: any) => {
		// Add logger to the original fetcher.
		const extendedFetcher = (...args: any) => {
			// @ts-ignore
			return fetcher(...args);
		};

		// Execute the hook with the new fetcher.
		return useSWRNext(key, extendedFetcher, config);
	};
}

const FOR_ACCOUNT_STATUS = 'for_account_status';
interface Props<T> {
	/**
	 * URL to send the request to recover the resources
	 *
	 * Example: /api/items
	 */
	url: string;

	/**
	 * The query parameter we send in the request to tell the server
	 * how many resources to send us back
	 *
	 * Example: limit: 50 => /api/items?limit=50
	 */
	limit?: number;

	/**
	 * Query parameter that we will watch with react-router to fetch another
	 * page
	 *
	 * Example: param: itemPage => /items?itemPage=2
	 */
	param: string;

	/**
	 * Optional parameter that allow us to specify how to transform the response
	 * from the server to one format to another
	 *
	 * Example: response: { service_id: 1 } => { id: response.service_id }
	 */
	transformer?: (value: any) => T;

	/**
	 * Query params that we will keep track of to send to the server
	 */
	queryParams?: string[];

	/**
	 * Query params for search
	 */
	querySearch?: string;

	/**
	 * Optional parameter for permissions
	 */
	canHaveActions?: boolean;
}

interface PaginationState {
	/**
	 * Keeps track of whether this if the first time we're making a fetch call
	 */
	initialFetch: boolean;

	/**
	 * State to keep tracking of whether or not we're fetching something from
	 * the server
	 */
	// loading: boolean;

	/**
	 * The total amount of resources that the server has for this endpoint
	 */
	total: number;
}

/**
 * usePagination Hook
 *
 * @param
 * @returns
 */
export default function usePagination<T>({
	url,
	limit,
	param,
	transformer,
	queryParams,
	querySearch,
	canHaveActions
}: Props<T>) {
	// Query parameters for the server
	// const [queryParams, setQueryParams] = useState<string>('');
	const query = useQuery();
	const history = useHistory();
	const [state, updateState] = useState<PaginationState>({ total: 0, initialFetch: true });
	const { setNotification } = useContext(DrawContext);
	const { simulatedUser, simulatedCustomer } = useSelector(
		(state: RootStore) => state.simulatedUser
	);
	const { isRootOrAgent } = usePermissions();

	let matchLabels = useRouteMatch('/envios');
	let matchBusiness = useRouteMatch('/centro-costos');

	const fetcher = useCallback(
		async (
			url: string,
			page: string | number,
			// perPage: string | number,
			...params: string[]
		) => {
			updateState((prevState) => ({ ...prevState, loading: true }));
			let endpoint = url;
			if (!queryParams?.includes('page')) {
				endpoint = `${url}?page=${page}`;
			}

			if (!!queryParams && !!params?.length) {
				endpoint +=
					(endpoint.includes('=') ? '&' : '?') +
					params
						.map((queryValue, queryIndex) => {
							const value: string =
								typeof queryValue === 'number' ? (queryValue as number)?.toString() : queryValue;
							if (queryValue === FOR_ACCOUNT_STATUS) {
								return `${FOR_ACCOUNT_STATUS}=true`;
							}
							if (!value) {
								return null;
							}
							if (value.includes('=')) {
								return value;
							}
							return `${queryParams[queryIndex]}=${value}`;
						})
						.filter((param) => Boolean(param))
						.join('&');
			}

			// TODO: which other route needs customer_id (even with user_id)
			if (
				(isRootOrAgent && matchBusiness && !endpoint.includes('customer_id')) ||
				canHaveActions === false
			)
				return;
			try {
				const response = await axios.get(endpoint);
				// Keep reference of total of elements so we can display in the DataGrid
				const state = { total: response.data.total } as PaginationState;
				state.initialFetch = false;
				updateState(state);
				return transformer ? response.data.data.map(transformer) : response.data.data;
			} catch (error) {
				setNotification({
					message: `Hubo un error al obtener ${matchBusiness ? 'las razones sociales' : matchLabels ? 'las guías' : 'la información'
						}.`,
					severity: 'error'
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isRootOrAgent, queryParams, matchBusiness, matchLabels, transformer]
	);

	// The key for the request will be base on url + number of page
	const { data, error, isValidating, mutate } = useSWR<T[]>(
		() => {
			const key = [url, query.get(param) || 1];

			if (!queryParams) {
				return key;
			}

			// ?carrier=estafeta
			for (let i = 0; i < queryParams.length; ++i) {
				const queryName = queryParams[i];
				if (
					query.get(queryName) !== null &&
					queryName !== 'user_id' &&
					queryName !== 'customer_id'
				) {
					key.push(query.get(queryName) as string | number);
					continue;
				} else if (queryName === 'user_id' && !!simulatedUser) {
					key.push(simulatedUser?.id);
					continue;
				} else if (
					queryName === 'customer_id' &&
					!!simulatedCustomer &&
					!key?.includes('user_id')
				) {
					key.push(simulatedCustomer?.id);
					continue;
				} else if (queryName === FOR_ACCOUNT_STATUS) {
					key.push(FOR_ACCOUNT_STATUS);
					continue;
				} else if (queryName === 'limit' && limit) {
					key.push(limit);
					continue;
				}
				if (!!querySearch && !key?.includes(querySearch)) {
					key.push(`query=${querySearch}`);
					continue;
				}

				if (!!querySearch && !key?.includes(querySearch)) {
					key.push(`query=${querySearch}`);
					continue;
				}

				return null;
			}

			return key;
		},
		fetcher,
		{ revalidateOnFocus: false }
	);

	function onPageChange(page: number, details: GridCallbackDetails) {
		// We get the query parameters from the current URL in the browser
		const keys = Array.from(query.keys());

		// This case covers for example when go to a page without specifying the pagination param
		// For example if we go to: /paquetes
		if (!keys.includes(param)) {
			keys.push(param);
		}

		let url = keys
			.map((key) => {
				// If current key matches the one for this paginator, then we modify it
				if (key === param) {
					// DataGrid works with based-0 index for the pagination, that's why we add 1
					return `${key}=${page + 1}`;
				}

				// If there is other params, we leave as they are
				return `${key}=${query.get(key)}`;
			})
			.join('&');

		// Replace the current url to cause the fetch of the given module
		history.replace(`${history.location.pathname}?${url}`);
	}

	return {
		total: state.total,
		data: data,
		loading: isValidating,
		onPageChange,
		page: parseInt(query.get(param) || '1', 10) - 1,
		initialFetch: state.initialFetch,
		error,
		mutate
	};
}