import React, { useRef, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	Select,
	TextField,
	Typography,
	Checkbox
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Controller } from 'react-hook-form';
import { ChevronDown } from 'react-feather';
import { useDispatch } from 'react-redux';
import IOSSwitch from '../Buttons/IOSSwitch';

import { useCardStyles } from '../../hooks/useStyles';

import { AddressRequest } from '../../store/actions/ActionTypes';
import { setPickUpConsolidated, setDeliverConsolidated } from '../../store/actions/';
import { HeadingTwo, TextTwo } from '~components/Headings';
import capitalize, { capitalizeAll } from '~util/capitalize';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(3)
		// '&>*': {
		// 	marginTop: theme.spacing(2)
		// }
	}
}));
interface Props {
	type: 'Origen' | 'Destino' | 'Recolectar en';
	address: AddressRequest | null;
	showTitle: boolean;
	isConsolidating?: boolean;
	// React Hook Form
	control?: any;
	errors?: any;
	onlyAddress?: boolean;
	carrier?: string;
	destinationBranchIsAvailable?: boolean;
	setIsToBranch?: (is: boolean) => void;
	isToBranch?: { value: boolean; disable: boolean };
}

const AddressSummaryCard: React.FC<Props> = (props) => {
	const classes = useStyles();
	const { cardClasses } = useCardStyles();
	const dispatch = useDispatch();

	const [showSpecials, setShowSpecials] = useState(false);


	const formatAddress = (address?:string) => {
		return !address ? '' : capitalizeAll(address?.toLowerCase());
	}

	const fullAddress = useMemo(
		() =>
			!props.address?.address && !props.address?.street
				? ''
				: props.address?.address && !props.address?.number
				? formatAddress(props.address?.address)
				: !!props.address?.street
				? `${formatAddress(props.address?.street)} ${formatAddress(props.address?.street2) ?? ''}${
						props.address?.number ? ' #' + props.address?.number + ',' : ','
				  }`
				: formatAddress(props.address.address),
		[props]
	);


	const maneuvers_field_name = useRef(
		`${props.type === 'Destino' ? 'destination' : 'origin'}_maneuvers`
	);
	const three_mts_distance = useRef(
		`${props.type === 'Destino' ? 'destination' : 'origin'}_three_meter_distance`
	);
	const pickup_description = useRef(
		`${props.type === 'Destino' ? 'destination' : 'origin'}_pickup_description`
	);

	useEffect(() => {
		if (props.type === 'Destino') {
			dispatch(setDeliverConsolidated(showSpecials));
		} else {
			dispatch(setPickUpConsolidated(showSpecials));
		}
	}, [showSpecials, dispatch, props.type]);

	return (
		<Grid container className={clsx([cardClasses.root, classes.root])}>
			{props.showTitle && (
				<Grid item xs={12} container>
					<Grid item xs={12}>
						<HeadingTwo text={props.type} />
					</Grid>
					{props.destinationBranchIsAvailable && (
						<Grid item xs={12} style={{ minHeight: '40px', marginBottom: '6px' }}>
							<FormControlLabel
								control={
									<Checkbox
										disabled={props.isToBranch?.disable}
										color='primary'
										checked={props.isToBranch?.value}
										onChange={(e) => props.setIsToBranch && props.setIsToBranch(e.target.checked)}
									/>
								}
								label={<div>Entrega sucursal en destino</div>}
							/>
						</Grid>
					)}
				</Grid>
			)}
			<Grid item xs={12}>
				<b>{props.address?.business_name ? props.address?.business_name?.toUpperCase() : ''}</b>
				<br />
				<b>{`${props.address?.city ? props.address?.city + ', ' : ''} ${
					props.address?.state ? props.address?.state + ', ' : ''
				}México, CP:${props.address?.zip_code !== '00000' ? props.address?.zip_code : ' S/D'}`}</b>
				<br />
				{`${fullAddress} ${
					props.address?.suite_number ? `Int. ${props.address.suite_number}` : ''
				} ${
					props.address?.neighborhood && props.carrier !== 'dhl'
						? `Col. ${props.address?.neighborhood}`
						: ''
				}`}
			</Grid>
			{((!props.onlyAddress && props.address?.contact) || props.showTitle) && (
				<Grid item xs={12}>
					<TextTwo text='Contacto' style={{ fontWeight: 'bolder', padding: '8px 0 0 0' }} />
					<b>{`${capitalize(props.address?.contact?.name)}`}</b>
					<br />
					{`Tel: ${props.address?.contact?.phone_number || 'S/D'}`}
					<br />
					{`Correo: ${props.address?.contact?.email || 'S/D'}`}
				</Grid>
			)}
			{props.isConsolidating && (
				<Grid item xs={12}>
					<Accordion
						style={{ boxShadow: 'none' }}
						expanded={showSpecials}
						onChange={() => setShowSpecials((prev) => !prev)}
					>
						<AccordionSummary expandIcon={<ChevronDown />}>
							<Typography variant='h6'>
								{props.type === 'Destino' ? (
									'Entrega a domicilio'
								) : (
									<React.Fragment>Recolección&nbsp;&nbsp;&nbsp;</React.Fragment>
								)}
							</Typography>
							<FormControlLabel
								onClick={(event) => event.stopPropagation()}
								onFocus={(event) => event.stopPropagation()}
								control={
									<IOSSwitch
										checked={showSpecials}
										onChange={() => setShowSpecials((prev) => !prev)}
									/>
								}
								label='SÍ'
							/>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container spacing={2}>
								<Grid container justifyContent='space-around' item xs={12}>
									<Grid item xs={7}>
										¿Requiere maniobras de {props.type === 'Destino' ? 'des' : null}carga?
									</Grid>
									<FormControl>
										<Controller
											as={
												<Select
													error={Boolean(
														props.errors ? props.errors[maneuvers_field_name.current] : ''
													)}
													native
													style={{ minWidth: 80 }}
												>
													<option aria-label='None' value='' />
													<option value={'Sí'}>Sí</option>
													<option value={'No'}>No</option>
													<option value={'No sé'}>No sé</option>
												</Select>
											}
											rules={{
												required: showSpecials ? 'Seleccione' : false
												//pattern: {}, nah
											}}
											name={maneuvers_field_name.current}
											control={props.control}
											defaultValue=''
										/>
										<FormHelperText>
											{props.errors && props.errors[maneuvers_field_name.current]
												? props.errors[maneuvers_field_name.current].message
												: null}
										</FormHelperText>
									</FormControl>
								</Grid>

								<Grid container justifyContent='space-around' alignItems='center' item xs={12}>
									<Grid item xs={7}>
										¿La mercancía se {props.type === 'Destino' ? 'entrega' : 'recolecta'} a no más
										de 3 metros de distancia de donde se estaciona el vehículo?
									</Grid>
									<FormControl>
										<Controller
											as={
												<Select
													error={Boolean(
														props.errors ? props.errors[three_mts_distance.current] : ''
													)}
													native
													style={{ minWidth: 80 }}
												>
													<option aria-label='None' value='' />
													<option value={'Sí'}>Sí</option>
													<option value={'No'}>No</option>
													<option value={'No sé'}>No sé</option>
												</Select>
											}
											rules={{
												required: showSpecials ? 'Seleccione' : false
											}}
											name={three_mts_distance.current}
											control={props.control}
											defaultValue=''
										/>
										<FormHelperText>
											{props.errors && props.errors[three_mts_distance.current]
												? props.errors[three_mts_distance.current].message
												: null}
										</FormHelperText>
									</FormControl>
								</Grid>

								<Grid container item xs={12}>
									<Grid item xs={9}>
										Describe cómo y dónde sería la{' '}
										{props.type === 'Destino' ? 'entrega' : 'recolección'}
									</Grid>
									<Controller
										as={
											<TextField
												error={Boolean(
													props.errors ? props.errors[pickup_description.current] : ''
												)}
												multiline
												minRows={4}
												placeholder='La unidad se encuentra a 300 metros de la mercancía en el segundo piso'
												helperText={
													props.errors && props.errors[pickup_description.current]
														? props.errors[pickup_description.current].message
														: null
												}
											/>
										}
										rules={{
											required: showSpecials ? 'Proporcione una descripción' : false
										}}
										name={pickup_description.current}
										control={props.control}
										defaultValue=''
									/>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Grid>
			)}
		</Grid>
	);
};

export default AddressSummaryCard;
