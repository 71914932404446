import React, { useContext, useRef, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@mui/styles';
import DrawContext from '../../context/DrawContext';

import AddressCollapse from '../ContentForCard/AddressCollapse';
import Map from '../Maps/Map';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: {
		maxHeight: 490,
		height: '80%',
		width: 290,
		marginBottom: '10%',
		marginTop: 20,
		marginRight: 20,
		borderRadius: 14,
		padding: 0,
		overflow: 'auto',
		overflowX: 'hidden'
	}
});

const RCard: React.FC = () => {
	const { rCardStatus } = useContext(DrawContext);

	// FIX to the error of the carousel
	const mounted = useRef(true);
	useEffect(() => {
		return () => {
			mounted.current = false;
		};
	}, []);

	const classes = useStyles();
	return (
		<Card className={clsx([classes.root])}>
			{rCardStatus === 'MAPS' && <Map />}
			{rCardStatus === 'SUMMARY_ADDRESS' && <AddressCollapse />}
		</Card>
	);
};

export default RCard;
