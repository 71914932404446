import { forwardRef, Ref, ReactElement, useCallback, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Slide,
	CircularProgress
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { TransitionProps } from '@mui/material/transitions';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axiosInstance from '~util/axios';
// Components
import { HeadingOne, HeadingTwo } from '~components/Headings';
import IncidentRegister from '~pages/shipments/Incident/Forms/IncidentRegister';
import useIncidentStore from '~store/IncidentStore';
// Utils
import { IncidentType } from '~util/incidents';
import { setSimulatedCustomer, setSimulatedUser } from '~store/actions/agents';
import { useDispatch } from 'react-redux';

export const VIEWS_REF = [
	{ name: IncidentType.INCORRECT, type: 0 },
	{ name: IncidentType.NO_MOVEMENT, type: 0 },
	{ name: IncidentType.DATA_CORRECTION, type: 0 },
	{ name: IncidentType.COORDINATE_TO_OFFICE, type: 1 },
	{ name: IncidentType.ROBBERY, type: 2 },
	{ name: IncidentType.LOST_MERCHANDISE, type: 3 },
	{ name: IncidentType.SUBTRACTION, type: 4 },
	{ name: IncidentType.DAMAGE_MERCHANDISE, type: 4 },
	{ name: IncidentType.RETURN_TO_ORIGIN, type: 5 },
	{ name: IncidentType.DECOMMISED, type: 5 },
	{ name: IncidentType.STOPPED, type: 5 }
];

const useStyles = makeStyles((theme: Theme) => ({
	spacing: {
		padding: `0 ${theme.spacing(2)}`
	},
	title: {
		padding: `0 0 0 ${theme.spacing(4)}`
	},
	contentContainer: {
		minWidth: '420px',
		margin: `0 0 ${theme.spacing(3)} 0`,
		'& > div': {
			padding: theme.spacing(1)
		},
		'& > p': {
			paddingLeft: `${theme.spacing(1)} !important`
		}
	},
	btn: {
		padding: `0 ${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(3)}`
	},
	cancelCross: {
		display: 'flex',
		justifyContent: 'flex-end',
		'& div': {
			margin: '8px 8px 0 0',
			cursor: 'pointer',
			width: '14px',
			height: '14px',
			'& svg:hover': {
				backgroundColor: 'rgba(255, 255, 255, 0.08)',
				borderRadius: '50%',
				color: 'white'
			}
		}
	}
}));

type DialogProps = {
	isOpen: boolean;
	handleClose: () => void;
	// handleClickConfirm: () => void;
	loading?: boolean;
	defaultValues: any | null;
};

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children: ReactElement<any, any> },
	ref: Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const IncidentFormModal = ({ isOpen, handleClose, loading, defaultValues }: DialogProps) => {
	const classes = useStyles();
	const history = useHistory();

	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const dispatch = useDispatch();
	const { handleSubmit, errors, control } = useForm();
	const { setLabel, setIncidentType } = useIncidentStore();

	// Set simulated customer before editing
	const setCustomerBeforeEdit = useCallback(
		async ({ customerId, user }: { customerId: number; user?: { name: string; id: number } }) => {
			try {
				const { data } = await axiosInstance.get(`/api/customer/${customerId}`);
				if (!data) return;
				dispatch(setSimulatedCustomer(data));
				if (!!user) {
					const result = await axiosInstance.get(`/api/user/${user?.id}`);
					dispatch(setSimulatedUser(result?.data));
				}
			} catch (error) {
				console.error(error);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const onSubmit = useCallback(
		async (data: any) => {
			setLoadingSubmit(true);
			const label = {
				...defaultValues
			};
			setLabel(label);
			setIncidentType(data.incident_type);
			await setCustomerBeforeEdit({
				customerId: defaultValues.customer?.id,
				user: defaultValues.user
			});
			history.push(`/registrar-incidente`);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[defaultValues, history, setCustomerBeforeEdit]
	);

	return (
		<Dialog
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-labelledby={'modal registrar incidente'}
			className={classes.spacing}
			PaperProps={{ style: { minWidth: '800px' } }}
		>
			<div className={classes.cancelCross}>
				<div onClick={handleClose}>
					<X size={16} />
				</div>
			</div>
			<DialogTitle className={classes.title} id='alert-dialog-slide-title'>
				<HeadingOne text={'Registrar Incidente'} />
			</DialogTitle>
			{!defaultValues ? (
				<CircularProgress />
			) : (
				<DialogContent className={classes.contentContainer}>
					{/* <DialogContentText id='alert-dialog-slide-description'>texto</DialogContentText> */}
					<HeadingTwo text={'Datos de la guía'} />
					<IncidentRegister control={control} errors={errors} defaultValues={defaultValues} />
				</DialogContent>
			)}
			<DialogActions className={classes.btn}>
				<Button onClick={handleClose} variant='outlined'>
					Cancelar
				</Button>
				<Button onClick={handleSubmit(onSubmit)} color='primary' variant='contained'>
					{loading || loadingSubmit ? <CircularProgress size={20} color='inherit' /> : 'Confirmar'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default IncidentFormModal;
