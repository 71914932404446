import { FC, Fragment, useCallback, useContext } from 'react';
import { Drawer, SwipeableDrawer, Grid, Theme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DrawContext from '~context/DrawContext';
import CustomIconButton from '~components/Buttons/CustomIconButton';
import { clearAddressesData, resetFlow, resetPickup } from '~store/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import usePermissions from '~hooks/usePermissions';
import { RootStore } from '~store/store';
import menuImages from '../../assets/menu/index';
import {
	SendIcon,
	RateIcon,
	PickupIcon,
	NotebookIcon,
	HistoryIcon,
	ClientsIcon,
	AgentsIcon,
	BillingIcon
} from '~components/MenuIcons/MenuIcons';
import useWalletStore from '~store/WalletStore';
import { shallow } from 'zustand/shallow';
import { useTheme } from '~hooks/useTheme';
import { Hidden } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => {
	return {
		rootList: {
			'&>:first-child': {
				'&>*:hover': {
					backgroundColor: 'inherit'
				}
			},
			'&>*': {
				padding: '6px'
			},
			'&>* > button': {
				color: theme.palette.white.main,
				maxWidth: 'unset',
				width: '76px',
				height: '68px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',

				'&>*:hover': {
					color: '#fff'
				}
			}
		},
		paperLeftDrawer: {
			//paddingTop: theme.spacing(1),
			// paddingBottom: theme.spacing(1.5),
			left: 4,
			top: 30,
			height: '100%',
			boxSizing: 'border-box',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: `${theme.palette.background.default} !important`,
			border: 'none !important',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: '11 !important'
		},
		icon: {
			fontFamily: 'Gotham',
			fontStyle: 'normal',
			fontWeight: 400,
			fontSize: '11px',
			lineHeight: '12px',
			width: '26px'
		},
		homeIcon: {
			width: '36px',
			height: '36px',
			backgroundImage: `url(${menuImages.home})`,
			'&:hover': {
				backgroundImage: `url(${menuImages.homeHover})`
			}
		},
		label: {
			fontSize: '11px',
			lineHeight: '12px',
			fontWeight: 400,
			margin: '6px 0px 0px 0px'
		}
	};
});

/**
 * Menú de la izquierda
 */

const LDrawer: FC = () => {
	const classes = useStyles();

	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const currentFlow = useSelector((state: RootStore) => state.flow.currentFlow);
	const { mobileLeftDrawerStatus, toggleLeftDrawer } = useContext(DrawContext);
	const {
		isRoot,
		isRootOrAgent,
		canUseLibreta,
		canUseEnvios,
		can,
		isSeller,
		isBiller,
		isProspect
	} = usePermissions();
	const resetBusinessTabs = useWalletStore((state) => state.reset, shallow);

	const matchesLG = useMediaQuery('(min-width:1360px)');
	const { themeV4 } = useTheme();

	const leftMenu = useCallback(
		() => (
			<Grid
				className={classes.rootList}
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
			>
				{/* TODO: ??? */}
				{!isProspect && (matchesLG || !isRootOrAgent) ? (
					<Grid item style={{ padding: 0, margin: 0 }}>
						<CustomIconButton
							onClick={() => {
								dispatch(resetFlow());
								history.push('/');
							}}
						>
							<div className={classes.homeIcon}></div>
						</CustomIconButton>
					</Grid>
				) : (
					<div></div>
				)}

				{can('canCreateUpdateDelete.label') && (
					<Grid item>
						<CustomIconButton
							onClick={() => {
								if (location.pathname.includes('enviar')) return;
								history.push('/enviar');
								dispatch(clearAddressesData());
							}}
						>
							<SendIcon color={currentFlow === 'send' ? themeV4.palette.primary.main : 'white'} />
							<p className={classes.label}>Enviar</p>
						</CustomIconButton>
					</Grid>
				)}

				{can('canCreateUpdateDelete.rate') && (
					<Grid item>
						<CustomIconButton
							onClick={() => {
								if (location.pathname.includes('cotizar')) return;
								dispatch(resetFlow());
								history.push('/cotizar');
							}}
						>
							<RateIcon color={currentFlow === 'rate' ? themeV4.palette.primary.main : 'white'} />
							<p className={classes.label}>Cotizar</p>
						</CustomIconButton>
					</Grid>
				)}

				{can('canCreateUpdateDelete.pickup') && (
					<Grid item>
						<CustomIconButton
							onClick={() => {
								if (location.pathname.includes('recolectar')) return;
								dispatch(resetPickup());
								history.push('/recolectar');
							}}
						>
							<PickupIcon
								color={location.pathname === '/recolectar' ? themeV4.palette.primary.main : 'white'}
							/>
							<p className={classes.label}>Recolectar</p>
						</CustomIconButton>
					</Grid>
				)}

				{canUseEnvios && (
					<Grid item>
						<CustomIconButton
							onClick={() => {
								dispatch(resetFlow());
								history.push('/envios');
							}}
						>
							<HistoryIcon
								color={
									location.pathname === '/envios' ||
									location.pathname === '/recolecciones' ||
									location.pathname === '/dispersiones' ||
									location.pathname === '/seguimiento' ||
									location.pathname === '/historial-saldo'
										? themeV4.palette.primary.main
										: 'white'
								}
							/>
							<p className={classes.label}>Historial</p>
						</CustomIconButton>
					</Grid>
				)}

				{!isProspect && canUseLibreta && (
					<Grid item>
						<CustomIconButton
							onClick={() => {
								dispatch(resetFlow());
								history.push(can('canRead.addresses') ? '/direcciones' : '/paquetes');
							}}
						>
							<NotebookIcon
								color={
									location.pathname === '/direcciones' ||
									location.pathname === '/grupos' ||
									location.pathname === '/usuarios' ||
									location.pathname === '/paquetes'
										? themeV4.palette.primary.main
										: 'white'
								}
							/>
							<p className={classes.label}>Libreta</p>
						</CustomIconButton>
					</Grid>
				)}

				{(isRootOrAgent || isSeller || isBiller) && (
					<Grid item>
						<CustomIconButton
							onClick={() => {
								dispatch(resetFlow());
								history.push('/clientes');
							}}
						>
							<ClientsIcon
								color={
									location.pathname === '/clientes' || location.pathname === '/prospectos'
										? themeV4.palette.primary.main
										: 'white'
								}
							/>
							<p className={classes.label}>Clientes</p>
						</CustomIconButton>
					</Grid>
				)}
				{isRoot && (
					<Grid item>
						<CustomIconButton
							onClick={() => {
								dispatch(resetFlow());
								history.push('/agentes');
							}}
						>
							<AgentsIcon
								color={location.pathname === '/agentes' ? themeV4.palette.primary.main : 'white'}
							/>
							<p className={classes.label}>Agentes</p>
						</CustomIconButton>
					</Grid>
				)}
				{can('canRead.business') && (
					<Grid item>
						<CustomIconButton
							onClick={() => {
								dispatch(resetFlow());
								resetBusinessTabs();
								history.push('/facturas');
							}}
						>
							<BillingIcon
								color={
									location.pathname === '/control-panel' ||
									location.pathname.includes('centro-costos') ||
									location.pathname.includes('movimientos') ||
									location.pathname.includes('revision') ||
									location.pathname.includes('facturas') ||
									location.pathname === '/saldo'
										? themeV4.palette.primary.main
										: 'white'
								}
							/>
							<p className={classes.label}>Facturación</p>
						</CustomIconButton>
					</Grid>
				)}
			</Grid>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			classes.rootList,
			location.pathname,
			isRootOrAgent,
			isRoot,
			canUseEnvios,
			canUseLibreta,
			can,
			history,
			dispatch,
			currentFlow,
			classes.homeIcon,
			classes.label,
			matchesLG,
			isBiller,
			isSeller,
			resetBusinessTabs
		]
	);

	return (
		<Fragment>
			{/* Mobile */}
			<Hidden mdUp>
				<SwipeableDrawer
					classes={{ paper: classes.paperLeftDrawer }}
					anchor='left'
					open={mobileLeftDrawerStatus}
					onClose={toggleLeftDrawer}
					onOpen={toggleLeftDrawer}
				>
					{leftMenu()}
				</SwipeableDrawer>
			</Hidden>

			{/* Desktop */}
			<Hidden smDown>
				<Drawer
					classes={{ paper: classes.paperLeftDrawer }}
					anchor='left'
					variant='permanent'
					open={true}
				>
					{leftMenu()}
				</Drawer>
			</Hidden>
		</Fragment>
	);
};

export default LDrawer;
