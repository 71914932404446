import { useContext, useCallback, useMemo } from 'react';
import AuthContext, { PermissionsKey } from '~context/AuthContext';
import { UserRole } from '~store/actions/ActionTypes';

export default function usePermissions() {
	const { currentPermissions, currentUser } = useContext(AuthContext);

	const can = useCallback(
		(permission: PermissionsKey) => {
			const [p, m] = permission.split('.');

			// @ts-ignore
			return currentPermissions[p][m];
		},
		[currentPermissions]
	);

	const isRoot = useMemo(() => currentUser.role === UserRole.ROOT, [currentUser]);
	const isAgent = useMemo(() => currentUser.role === UserRole.AGENT, [currentUser]);
	const isSeller = useMemo(() => currentUser.role === UserRole.SELLER, [currentUser]);
	const isRootOrAgent = useMemo(() => isRoot || isAgent || isSeller, [isRoot, isAgent, isSeller]);
	const isCustomer = useMemo(() => currentUser.role === UserRole.CUSTOMER, [currentUser]);
	const isAgentOrCustomer = useMemo(() => isCustomer || isAgent, [isAgent, isCustomer]);
	const isClient = useMemo(() => currentUser.role === UserRole.CLIENT, [currentUser]);
	const isBiller = useMemo(() => currentUser.role === UserRole.BILLER, [currentUser]);
	const isProspect = useMemo(() => currentUser.role === UserRole.PROSPECT, [currentUser]);

	const canUseEnvios = useMemo(() => {
		return can('canRead.label') || can('canRead.pickup');
	}, [can]);

	const canUseLibreta = useMemo(() => {
		return (
			can('canRead.addresses') ||
			can('canRead.packages') ||
			can('canRead.users') ||
			can('canRead.groups')
		);
	}, [can]);

	return {
		can,
		isRoot,
		isAgent,
		isRootOrAgent,
		isAgentOrCustomer,
		isCustomer,
		isClient,
		isBiller,
		isSeller,
		canUseLibreta,
		canUseEnvios,
		isProspect
	};
}
