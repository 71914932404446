import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Box, Drawer } from '@mui/material';
import RCard from '../Cards/RCard';

const useStyles = makeStyles((theme: Theme) => ({
	mobileContainer: {
		[theme.breakpoints.down('xs')]: {
			display: 'block'
		},
		[theme.breakpoints.up('xs')]: {
			display: 'none'
		}
	},
	desktopContainer: {
		'& *': {
			background: `transparent !important`
		},
		[theme.breakpoints.down('lg')]: {
			display: 'none'
		},
		[theme.breakpoints.up('lg')]: {
			display: 'block'
		}
	},
	paperRightDrawer: {
		background: `${theme.palette.background.default} !important`,
		color: `${theme.palette.white.main} !important`,
		top: '10%',
		height: '90%',
		border: 'none',
		'& > div': {
			boxShadow: 'none'
		}
	},
	rightDrawButton: {
		position: 'fixed',
		right: 0,
		//bottom: '50%'
		top: '12%',
		maxHeight: 490,
		height: '80%',
		backgroundColor: theme.palette.background.paper,
		borderRadius: '14px 0 0 14px'
	}
}));

/**
 * Menú de la derecha
 */

const RDrawer: React.FC = () => {
	const classes = useStyles();

	// const { mobileRigthDrawerStatus, toggleRightDrawer } = useContext(DrawContext);

	return (
		<React.Fragment>
			{/* Laptop */}
			{/* <Box className={classes.mobileContainer}>
				<Button onClick={toggleRightDrawer} className={classes.rightDrawButton}>
					<Bell />
				</Button>
				<SwipeableDrawer
					classes={{ paper: classes.paperRightDrawer }}
					anchor='right'
					open={mobileRigthDrawerStatus}
					onClose={toggleRightDrawer}
					onOpen={toggleRightDrawer}
				>
					<RCard />
				</SwipeableDrawer>
			</Box> */}

			{/* Desktop */}
			<Box className={classes.desktopContainer}>
				<Drawer
					classes={{ paper: classes.paperRightDrawer }}
					anchor='right'
					variant='permanent'
					open={true}
				>
					<RCard />
				</Drawer>
			</Box>
		</React.Fragment>
	);
};

export default RDrawer;
