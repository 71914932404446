import { Grid, TextField, IconButton, Checkbox } from '@mui/material';
import { X } from 'react-feather';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import PhoneNumberInput from '~components/Inputs/InputForms/PhoneNumberInput';

/**
 * Create incident: resume notification section
 */

interface ListProps {
	fields: Record<string, any>[];
	errors: FieldErrors;
	control: Control;
	remove: (index: number) => void;
	multiple: boolean;
	getValues: (payload?: string | string[]) => Object;
}

interface ItemProps extends ListProps {
	index: number;
	field: Record<string, any>;
}

const IncidentNotificationRow = ({
	field,
	fields,
	errors,
	control,
	remove,
	multiple,
	index,
	getValues
}: ItemProps) => {
	const nameLabel = index === 0 ? 'Remitente' : index === 1 ? 'Destinatario' : 'Usuario';
	const active = useWatch({
		control,
		name: `notification_contacts.[${index}].active`,
		defaultValue: index === 0
	});

	const email = useWatch({
		control,
		name: `notification_contacts.[${index}].email`,
		defaultValue: null
	});

	const phone_number = useWatch({
		control,
		name: `notification_contacts.[${index}].phone_number`,
		defaultValue: null
	});

	return (
		<Grid item container spacing={1} key={field.key} alignItems='flex-start'>
			{multiple && (
				<Grid
					item
					xs={1}
					container
					alignItems='center'
					justifyContent='flex-end'
					style={{ marginTop: 10 }}
				>
					<IconButton onClick={() => remove(index)} disabled={fields?.length === 1}>
						<X />
					</IconButton>
				</Grid>
			)}
			<Grid item style={{ marginTop: 8 }}>
				<Controller
					control={control}
					name={`notification_contacts.[${index}].active`}
					type={'boolean'}
					defaultValue={index === 0 ? true : false}
					render={({ onChange, ...props }) => (
						<Checkbox
							{...props}
							color={'primary'}
							checked={props?.value}
							onChange={(e) => {
								const prevValues: any = getValues();
								if (
									!e.target.checked &&
									active &&
									Object.values(prevValues?.notification_contacts ?? {})?.filter(
										(item: any) => item?.active
									)?.length === 1
								)
									return;
								onChange(e.target.checked);
							}}
						/>
					)}
				/>
			</Grid>

			<Grid item xs={10} md={3}>
				<TextField
					name={`notification_contacts.[${index}].id`}
					style={{ display: 'none' }}
					defaultValue={null}
				/>

				<Controller
					as={
						<TextField
							fullWidth
							error={Boolean(errors?.notification_contacts?.[index]?.name)}
							variant='filled'
							label={`${nameLabel}${!!active ? '*' : ''}`}
							helperText={
								Boolean(errors?.notification_contacts?.[index]?.name) &&
								errors.notification_contacts[index]?.name?.message
							}
						/>
					}
					name={`notification_contacts.[${index}].name`}
					control={control}
					defaultValue={null}
					rules={{ required: { value: !!active, message: 'Ingrese nombre de contacto' } }}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<Controller
					as={
						<TextField
							fullWidth
							error={Boolean(errors?.notification_contacts?.[index]?.email)}
							variant='filled'
							label='Correo'
							helperText={
								Boolean(errors?.notification_contacts?.[index]?.email) &&
								errors.notification_contacts[index]?.email?.message
							}
						/>
					}
					name={`notification_contacts.[${index}].email`}
					control={control}
					defaultValue={null}
					rules={{ required: { value: !!active && !phone_number, message: 'Ingrese el correo' } }}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<PhoneNumberInput
					control={control}
					errors={errors}
					name={`notification_contacts.[${index}].phone_number`}
					defaultValue={undefined}
					required={!!active && !email}
					error={
						!!errors?.notification_contacts?.length
							? errors?.notification_contacts[index]?.phone_number
							: undefined
					}
				/>
			</Grid>
		</Grid>
	);
};

const IncidentNotifications = ({
	fields,
	errors,
	control,
	remove,
	multiple,
	getValues
}: ListProps) => {
	return (
		<Grid container spacing={2} alignItems='center'>
			{fields.map((item: any, index: number) => (
				<IncidentNotificationRow
					fields={fields}
					field={item}
					control={control}
					remove={remove}
					errors={errors}
					multiple={multiple}
					index={index}
					getValues={getValues}
				/>
			))}
		</Grid>
	);
};

export default IncidentNotifications;
