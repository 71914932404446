import produce from 'immer';
import { create } from 'zustand';
import { AddressRequest, Contact, ShipmentSendResponse } from '~store/actions/ActionTypes';
import { IncidentType } from '~util/incidents';

export type IncidentTypeField = { name: IncidentType; type: 0 | 1 | 2 | 3 | 4 | 5 };

export type IncidentsPackages = {
	packaging: string;
	value: number;
	insurance: boolean;
	description: string;
	content: string;
};

export type IncidentMerchandise = {
	brand: string;
	description: string;
	insurance: string;
	invoice: string;
	model: string;
	quantity: string;
	packaging: string;
	value: number;
};

export interface IncidentEvent {
	date: Date;
	status: string;
	created_by: string;
	description: string;
}
export interface IncidentDetail {
	id: number;
	created_at: Date;
	folio: string;
	carrier: string;
	service: string;
	parcel_number: string;
	tracking_number: string;
	origin: IncidentDetailAddress;
	destination: IncidentDetailAddress;
	incident: string;
	status: string;
	user: string;
	customer: string;
	description: string;
	type: string;
	events: IncidentEvent[];
	data: IncidentDetailData;
	carrier_folio?: string;
}

export interface IncidentDetailData {
	city: string;
	state: string;
	value: number;
	number: string;
	street: string;
	content: string;
	zip_code: string;
	packaging: string;
	reference: string;
	contact_name: string;
	location_url: string;
	neighborhood: string;
	business_name: string;
	reference_street: string;
	content_description: string;
	contact_phone_number: string;
	contact_type_phone_number: string;
	files?: { url: string; type: string; name: string; path?: string }[];
}

export interface IncidentDetailAddress {
	city: string;
	state: string;
	tax_id: string;
	address: string;
	contact: {
		name: string;
		email: string;
		phone_number: string;
	};
	zip_code: string;
	reference?: string;
	neighborhood: string;
	business_name: string;
}

export type IncidentFile = File;

type IncidentState = {
	label: ShipmentSendResponse | null;
	incidentType: IncidentTypeField | null;
	address: Partial<AddressRequest> | null;
	contacts: Contact[] | null;
	packages: IncidentsPackages[] | null;
	merchandise: IncidentMerchandise[] | null;
	damagedMerchandise: IncidentMerchandise[] | null;
	files: IncidentFile[] | null;
	setLabel: (label: ShipmentSendResponse | null) => void;
	setIncidentType: (incidentType: IncidentTypeField | null) => void;
	setAddress: (address: Partial<AddressRequest> | null) => void;
	setContacts: (contacts: Contact[] | null) => void;
	setPackages: (packages: IncidentsPackages[] | null) => void;
	setMerchandise: (merchandise: IncidentMerchandise[] | null) => void;
	setDamagedMerchandise: (merchandise: IncidentMerchandise[] | null) => void;
	setFiles: (files: IncidentFile[] | null) => void;
};

const useIncidentStore = create<IncidentState>((set) => ({
	label: null,
	incidentType: null,
	address: null,
	contacts: null,
	packages: null,
	merchandise: null,
	damagedMerchandise: null,
	files: null,
	setLabel: (label: ShipmentSendResponse | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.label = label;
			})
		),
	setIncidentType: (incidentType: IncidentTypeField | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.incidentType = incidentType;
			})
		),
	setAddress: (address: Partial<AddressRequest> | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.address = address;
			})
		),
	setContacts: (contacts: Contact[] | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.contacts = contacts;
			})
		),
	setPackages: (packages: IncidentsPackages[] | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.packages = packages;
			})
		),
	setMerchandise: (merchandise: IncidentMerchandise[] | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.merchandise = merchandise;
			})
		),
	setDamagedMerchandise: (merchandise: IncidentMerchandise[] | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.damagedMerchandise = merchandise;
			})
		),
	setFiles: (files: IncidentFile[] | null) =>
		set((state) =>
			produce(state, (draft) => {
				draft.files = files;
			})
		)
}));

export default useIncidentStore;
